import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div class="container">
          <div class="jumbotron">
            <h1 class="display-4">Who Is Grease?</h1>
            <h2 >Help us find clues!</h2>
            <table class="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Clues</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>🔍</td>
                  <td>Not an engineer</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Not social</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Lawyer</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Watches Anime</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Didn't like the venom movie</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Has a Hulu account</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Self proclamed "fucking menace"</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Plays Elden Ring</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Not lactose intollerant</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>No tats</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Cracked at Fortnite</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Likes Opossums</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Leaked Supreme Court Draft Opinion to Politico</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Self Identifies As A Turkey</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Demon Dom Peddler</td>
                </tr>
                <tr>
                  <td>🔍</td>
                  <td>Not a Succubus (probably)</td>
                </tr>
              </tbody>
              </table>
              </div>
          </div>
      </header>
    </div>
  );
}

export default App;
